import retry from 'async-retry';
import { ActionFunctionArgs, DataFunctionArgs, json, redirect } from "@remix-run/node";
import {
    Bird,
    Book,
    Bot,
    Building2,
    Code2,
    CornerDownLeft,
    LifeBuoy,
    Mic,
    Paperclip,
    Rabbit,
    Settings,
    Settings2,
    Share,
    SquareTerminal,
    SquareUser,
    Triangle,
    Turtle,
} from "lucide-react"
import { Badge } from "#app/components/ui/badge.tsx"
import { Button } from "#app/components/ui/button.tsx"
import {
    Drawer,
    DrawerContent,
    DrawerDescription,
    DrawerHeader,
    DrawerTitle,
    DrawerTrigger,
} from "#app/components/ui/drawer.tsx"
import { Input } from "#app/components/ui/input.tsx"
import { Label } from "#app/components/ui/label.tsx"
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "#app/components/ui/select.tsx"
import { Textarea } from "#app/components/ui/textarea.tsx"
import {
    Tooltip,
    TooltipContent,
    TooltipTrigger,
} from "#app/components/ui/tooltip.tsx"
import { useState } from 'react'
import { requireUserId } from "#app/utils/auth.server.ts";
import { getZodConstraint, parseWithZod } from "@conform-to/zod";
import { z } from "zod";
import { prisma } from "#app/utils/db.server.ts";
import { getFormProps, getInputProps, useForm } from "@conform-to/react";
import { useFetcher, useLoaderData } from "@remix-run/react";
import { ErrorList, Field, SelectField } from "#app/components/forms.tsx";
import { logger } from "#app/utils/logger.server.ts";
import { Highlight, themes } from "prism-react-renderer"
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "#app/components/ui/tabs.tsx"
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "#app/components/ui/card.tsx";
import { Avatar, AvatarFallback, AvatarImage } from "#app/components/ui/avatar.tsx";
import { StatusButton } from "#app/components/ui/status-button.tsx";
import { toast } from "sonner";
import { useEffect } from "react";
import { BaseError, HTTPError } from "#app/utils/errors.ts";

const RETRY_CONFIG = {
    retries: 3,
    minTimeout: 500,
    maxTimeout: 5000
}

const requestIntent = 'make-api-request';

export async function action({ request }: ActionFunctionArgs) {
    const userId = await requireUserId(request)
    const formData = await request.formData()
    const intent = formData.get('intent')
    switch (intent) {
        case requestIntent: {
            try {
                return await callAPI({ request, userId, formData })
            } catch (err) {
                logger.error({ err, userId }, 'Error calling API');
                return json({
                    error: {
                        title: 'Error calling API',
                        description: `We ran into an issue. Please try again.`
                    }
                }, { status: 500 })
            }
        }
        default: {
            throw new Response(`Invalid intent "${intent}"`, { status: 400 })
        }
    }
}

type ActionArgs = {
    request: Request
    userId: string
    formData: FormData
}

const Schema = z.object({
    api: z.literal('security'),
    domain: z.string(),
})

const EntityResolutionSchema = z.object({
    api: z.literal('entity_resolution'),
    name: z.string(),
    location: z.string().optional(),
})

const EnrichmentSchema = z.object({
    api: z.literal('enrichment'),
    domain: z.string(),
})

async function _callSecurityAPI(key: string, domain: string) {
    try {
        let res = await fetch(`${process.env.KONG_GATEWAY_URL}/v1/security/search?domain=${domain}`, {
            headers: {
                'apikey': key,
            }
        });

        let body = await res.json();

        if (res.ok) {
            return {
                res: {
                    api: 'security',
                    data: body,
                },
                status: res.status,
            }
        } else {
            logger.error({ domain, body }, 'Error calling search API');
            throw new HTTPError('Error calling API', res.status, 'http_error', body);
        }
    } catch (err) {
        logger.error({ err }, 'Error calling API');
        throw err;
    }
}

async function _callEntityResolution(key: string, query: any) {
    let params = new URLSearchParams({ name: query.name });
    if (query.location) {
        params.set("location", query.location);
    }

    try {
        let res = await fetch(`${process.env.KONG_GATEWAY_URL}/v1/entity-resolution/stream?${params}`, {
            headers: {
                'apikey': key,
            }
        });

        let body = await res.json();

        if (res.ok) {
            return {
                res: {
                    api: 'entity_resolution',
                    data: body,
                },
                status: res.status,
            }
        } else {
            logger.error({ query, body }, 'Error calling _callEntityResolution API');
            throw new HTTPError('Error calling API', res.status, 'http_error', body);
        }
    } catch (err) {
        logger.error({ err }, 'Error calling _callEntityResolution API');
        throw err;
    }
}

async function _callEnrichment(key: string, domain: string) {
    try {
        let res = await fetch(`${process.env.KONG_GATEWAY_URL}/v1/enrichment/stream?domain=${domain}`, {
            headers: {
                'apikey': key,
            }
        });

        let body = await res.json();

        if (res.ok) {
            return {
                res: {
                    api: 'enrichment',
                    data: body,
                },
                status: res.status,
            }
        } else {
            logger.error({ domain, body }, 'Error calling enrichment API');
            throw new HTTPError('Error calling API', res.status, 'http_error', body);
        }
    } catch (err) {
        logger.error({ err }, 'Error calling enrichment API');
        throw err;
    }
}

async function loadAPIKey(userId: string) {
    let keys = await prisma.$transaction(async (tx) => {
        let user = await tx.user.findFirstOrThrow({
            select: {
                account: {
                    select: {
                        account: {
                            select: {
                                id: true,
                                consumerId: true,
                                apiKeys: {
                                    select: {
                                        id: true,
                                        key: true,
                                    }
                                }
                            },
                        },
                    },
                }
            },
            where: {
                id: userId,
            },
        });

        if (!user?.account) {
            throw new Error('Account not found');
        }
        if (!user.account.account.consumerId) {
            throw new Error('Consumer not found');
        }

        return user.account.account.apiKeys;
    })

    let first = keys[0];
    if (!first) {
        throw new Error('No API keys found');
    }
    return first.key;
}

async function _parseSubmision(formData: FormData) {
    const api = formData.get('api');

    let submission;
    if (api === 'entity_resolution') {
        submission = await parseWithZod(formData, {
            async: true,
            schema: EntityResolutionSchema
        })
    }
    else if (api === 'enrichment') {
        submission = await parseWithZod(formData, {
            async: true,
            schema: EnrichmentSchema
        })
    }
    else {
        submission = await parseWithZod(formData, {
            async: true,
            schema: Schema
        })
    }

    return submission;
}

async function callAPI({ request, userId, formData }: ActionArgs) {
    const submission = await _parseSubmision(formData);

    if (submission.status !== 'success') {
        return json(
            { result: submission.reply() },
            { status: submission.status === 'error' ? 400 : 200 },
        )
    }

    let key = await loadAPIKey(userId);

    const { api } = submission.value;
    if (api === 'entity_resolution') {
        let res = await _callEntityResolution(key, submission.value);
        return json(res, {
            status: res.status,
        })
    }
    else if (api === 'enrichment') {
        let res = await _callEnrichment(key, submission.value.domain);
        return json(res, {
            status: res.status,
        })
    }
    else {
        let res = await _callSecurityAPI(key, submission.value.domain);
        return json(res, {
            status: res.status,
        })
    }
}

function responseCodeHighlight(body: any) {
    return (
        <div className="py-6 px-4">
            <Highlight
                // theme={themes.shadesOfPurple}
                code={JSON.stringify(body, null, 2)}
                language="js"
            >
                {({ className, style, tokens, getLineProps, getTokenProps }) => (
                    <pre className="whitespace-pre-wrap py-4 px-2" style={style}>
                        {tokens.map((line, i) => (
                            <div key={i} {...getLineProps({ line })}>
                                <span>{i + 1}</span>
                                {line.map((token, key) => (
                                    <span key={key} {...getTokenProps({ token })} />
                                ))}
                            </div>
                        ))}
                    </pre>
                )}
            </Highlight>
        </div>
    )
}

function responsePretty(body: any) {
    let business = body.business;
    let summary = body?.summary;
    let people = body.summary?.people || [];
    let securityPractices = body.summary?.securityPractices || [];
    let compliance = body.summary?.compliance || [];
    let subprocessors = body.summary?.subprocessors || [];
    return (
        <div>
            <Card x-chunk="dashboard-05-chunk-1">
                <CardHeader className="pb-2">
                    <CardDescription>Vendor report for:</CardDescription>
                    <CardTitle className="text-4xl">{body.domain}</CardTitle>
                </CardHeader>
                <CardContent>

                    <div className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 py-6">
                        <dt className="font-medium">Name</dt><dd className="sm:col-span-2 text-muted-foreground opacity-80 ">{business.name}</dd>
                    </div>
                    <div className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 border-t-[1px] py-6">
                        <dt>Legal Name</dt><dd className="sm:col-span-2 text-muted-foreground opacity-80">{business.legalName}</dd>
                    </div>
                    <div className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 border-t-[1px] py-6">
                        <dt>Vendor Status</dt><dd className="sm:col-span-2 text-muted-foreground opacity-80">
                            <div>Is Software Vendor: {String(body?.vendor?.isSoftwareVendor)}</div>
                            <div>Confidence Level: {body?.vendor?.confidence}</div>
                        </dd>
                    </div>
                    <div className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 border-t-[1px] py-6">
                        <dt>Description</dt><dd className="sm:col-span-2 text-muted-foreground opacity-80">{business.description}</dd>
                    </div>
                    <div className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 border-t-[1px] py-6">
                        <dt>Industry</dt><dd className="sm:col-span-2 text-muted-foreground opacity-80">{business.industry}</dd>
                    </div>
                    <div className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 border-t-[1px] py-6">
                        <dt>NAISC Codes</dt><dd className="sm:col-span-2 text-muted-foreground opacity-80">{business.naics?.map(i => i.code).join(', ')}</dd>
                    </div>
                    <div className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 border-t-[1px] py-6">
                        <dt>Tags</dt><dd className="sm:col-span-2 text-muted-foreground opacity-80">{business?.tags?.join(', ')}</dd>
                    </div>

                    <div className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 border-t-[1px] py-6">
                        <dt>Terms of Service</dt><dd className="sm:col-span-2 text-muted-foreground opacity-80">{summary?.termsOfService}</dd>
                    </div>
                    <div className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 border-t-[1px] py-6">
                        <dt>Privacy Policy</dt><dd className="sm:col-span-2 text-muted-foreground opacity-80">{summary?.privacyPolicy}</dd>
                    </div>
                    <div className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 border-t-[1px] py-6">
                        <dt>Trust Center</dt><dd className="sm:col-span-2 text-muted-foreground opacity-80">{summary?.trustCenter}</dd>
                    </div>
                    <div className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 border-t-[1px] py-6">
                        <dt>Status Page</dt><dd className="sm:col-span-2 text-muted-foreground opacity-80">{summary?.statusPage}</dd>
                    </div>

                </CardContent>
                <CardFooter>
                    {/* <Progress value={25} aria-label="25% increase" /> */}
                </CardFooter>
            </Card>

            <Card className="mt-6">
                <CardHeader className="pb-2">
                    <CardTitle className="text-2xl">People</CardTitle>
                    <CardDescription>Key security people</CardDescription>
                </CardHeader>
                <CardContent>

                    {people?.map((person: any, index: number) => (
                        <div key={index} className="border-[1px] rounded-md mt-4">
                            <div key={person.id} className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 py-6 mx-6">
                                <dt className="font-medium">Name</dt><dd className="sm:col-span-2 text-muted-foreground opacity-80 ">{person.fullName}</dd>
                            </div>
                            <div key={person.id} className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 py-6 mx-6 border-t-[1px] py-6">
                                <dt className="font-medium">Headline</dt><dd className="sm:col-span-2 text-muted-foreground opacity-80 ">{person.headline}</dd>
                            </div>
                            <div key={person.id} className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 py-6 mx-6 border-t-[1px] py-6">
                                <dt className="font-medium">Title</dt><dd className="sm:col-span-2 text-muted-foreground opacity-80 ">{person.title}</dd>
                            </div>
                            <div key={person.id} className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 py-6 mx-6 border-t-[1px] py-6">
                                <dt className="font-medium">Start Date</dt><dd className="sm:col-span-2 text-muted-foreground opacity-80 ">{person.startDate}</dd>
                            </div>
                            <div key={person.id} className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 py-6 mx-6 border-t-[1px] py-6">
                                <dt className="font-medium">LinkedIn</dt><dd className="sm:col-span-2 text-muted-foreground opacity-80 ">{person.linkedinHandle}</dd>
                            </div>
                        </div>
                    ))}

                </CardContent>
                <CardFooter>
                    {/* <Progress value={25} aria-label="25% increase" /> */}
                </CardFooter>
            </Card>

            <div className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-2 py-6">
                <Card className="mt-6">
                    <CardHeader className="pb-2">
                        <CardTitle className="text-2xl">Security Controls</CardTitle>
                        {/* <CardDescription>Key security people</CardDescription> */}
                    </CardHeader>
                    <CardContent>

                        <ul className="list-disc pl-4">
                            {securityPractices?.map((item: any) => (
                                <li className="text-muted-foreground opacity-80">{item.value}</li>
                            ))}
                        </ul>

                    </CardContent>
                    <CardFooter>
                        {/* <Progress value={25} aria-label="25% increase" /> */}
                    </CardFooter>
                </Card>
                <Card className="mt-6">
                    <CardHeader className="pb-2">
                        <CardTitle className="text-2xl">Compliance</CardTitle>
                        {/* <CardDescription>Key security people</CardDescription> */}
                    </CardHeader>
                    <CardContent>

                        {<ul className="list-disc pl-4">
                            {compliance?.map((item: any) => (
                                <li className="text-muted-foreground opacity-80">{item.value}</li>
                            ))}
                        </ul>}

                    </CardContent>
                    <CardFooter>
                        {/* <Progress value={25} aria-label="25% increase" /> */}
                    </CardFooter>
                </Card>
            </div>

            <Card className="mt-6">
                <CardHeader className="pb-2">
                    <CardTitle className="text-2xl">Subprocessors</CardTitle>
                    <CardDescription>Identified subprocessors</CardDescription>
                </CardHeader>
                <CardContent>

                    {subprocessors?.map((sub: any, index) => (
                        <div className="flex pt-6 gap-4 ">
                            <div>
                                <Avatar
                                    size="md"
                                    className="mr-4"
                                    fallback={<AvatarFallback>{sub.companyName[0]}</AvatarFallback>}
                                >
                                    <AvatarImage src={`https://logo.bigpicture.io/logo/${sub.matchedDomain}`} alt={sub.companyName} />
                                </Avatar>
                            </div>
                            <div className="grow">
                                <div className="font-medium">{sub.companyName} - <span className="text-muted-foreground opacity-80">{sub.type}</span></div>
                                <div className="text-muted-foreground opacity-80">{sub.purpose}</div>
                            </div>
                            <div className="justify-self-end">
                                <div className="text-muted-foreground opacity-80">{sub.location}</div>
                            </div>
                        </div>
                    ))}

                </CardContent>
                <CardFooter>
                    {/* <Progress value={25} aria-label="25% increase" /> */}
                </CardFooter>
            </Card>


        </div>
    )
}

function responseEntityResolutionPretty(body: any) {
    let code = body?.code;
    let match = body?.match;
    let record = match?.record;
    let possible_matches = body?.possible_matches?.results || [];
    console.log(body);
    return (
        <div>
            <Card x-chunk="dashboard-05-chunk-1">
                <CardHeader className="pb-2">
                    <CardDescription>Entity Resolution</CardDescription>
                    <CardTitle className="text-4xl flex justify-between">
                        Result: {code}
                        {match && (<div>SIQ Score: {match?.score}</div>)}</CardTitle>
                </CardHeader>
                {match && (<CardContent>
                    <div className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 py-6">
                        <dt className="font-medium">Name</dt><dd className="sm:col-span-2 text-muted-foreground opacity-80 ">{record?.name?.value}</dd>
                    </div>
                    <div className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 border-t-[1px] py-6">
                        <dt>Legal Name</dt><dd className="sm:col-span-2 text-muted-foreground opacity-80">{record?.legal_name?.value}</dd>
                    </div>
                    <div className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 border-t-[1px] py-6">
                        <dt>DBAs</dt><dd className="sm:col-span-2 text-muted-foreground opacity-80">{record?.dba_names?.value?.join(', ')}</dd>
                    </div>
                    <div className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 border-t-[1px] py-6">
                        <dt>Description</dt><dd className="sm:col-span-2 text-muted-foreground opacity-80">{record?.description?.value}</dd>
                    </div>
                    <div className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 border-t-[1px] py-6">
                        <dt>Primary Address</dt><dd className="sm:col-span-2 text-muted-foreground opacity-80">{record?.primary_address?.value?.formatted_address}</dd>
                    </div>
                    <div className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 border-t-[1px] py-6">
                        <dt>Legal Address</dt><dd className="sm:col-span-2 text-muted-foreground opacity-80">{record?.legal_address?.value?.formatted_address}</dd>
                    </div>
                    <div className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 border-t-[1px] py-6">
                        <dt>Website</dt><dd className="sm:col-span-2 text-muted-foreground opacity-80">{record?.website?.value}</dd>
                    </div>
                </CardContent>)}
                <CardFooter>
                    {/* <Progress value={25} aria-label="25% increase" /> */}
                </CardFooter>
            </Card>

            {possible_matches.length > 0 && (<Card className="mt-6">
                <CardHeader className="pb-2">
                    <CardTitle className="text-2xl">Possible Matches</CardTitle>
                    <CardDescription>Other companies that are similar to the one you searched for</CardDescription>
                </CardHeader>
                <CardContent>

                    {possible_matches?.map((match: any, index: number) => {
                        let record = match?.record;
                        return (
                            <div key={index} className="border-[1px] rounded-md mt-4">
                                <div className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 py-6 mx-6">
                                    <dt className="font-medium">Name</dt><dd className="sm:col-span-2 text-muted-foreground opacity-80 ">{record?.name?.value}</dd>
                                </div>
                                <div className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 py-6 mx-6 border-t-[1px] py-6">
                                    <dt className="font-medium">Legal Name</dt><dd className="sm:col-span-2 text-muted-foreground opacity-80 ">{record?.legal_name?.value}</dd>
                                </div>
                                <div className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 py-6 mx-6 border-t-[1px] py-6">
                                    <dt className="font-medium">Description</dt><dd className="sm:col-span-2 text-muted-foreground opacity-80 ">{record?.description?.value}</dd>
                                </div>
                                <div className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 py-6 mx-6 border-t-[1px] py-6">
                                    <dt>Primary Address</dt><dd className="sm:col-span-2 text-muted-foreground opacity-80">{record?.primary_address?.value?.formatted_address}</dd>
                                </div>
                                <div className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 py-6 mx-6 border-t-[1px] py-6">
                                    <dt>Legal Address</dt><dd className="sm:col-span-2 text-muted-foreground opacity-80">{record?.legal_address?.value?.formatted_address}</dd>
                                </div>
                            </div>
                        )
                    })}

                </CardContent>
                <CardFooter>
                    {/* <Progress value={25} aria-label="25% increase" /> */}
                </CardFooter>
            </Card>)}
        </div>
    )
}

function responseEnrichmentPretty(body: any) {
    return (
        <div className="space-y-6">
            {/* Company Overview Card */}
            <Card>
                <CardHeader className="pb-2">
                    <CardDescription>Website Enrichment for:</CardDescription>
                    <CardTitle className="text-4xl flex items-center gap-4">
                        {body.name}
                        {body.logo && (
                            <Avatar className="h-12 w-12">
                                <AvatarImage src={body.logo} alt={body.name} />
                                <AvatarFallback>{body.name[0]}</AvatarFallback>
                            </Avatar>
                        )}
                    </CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 py-6">
                        <dt className="font-medium">Legal Name</dt>
                        <dd className="sm:col-span-2 text-muted-foreground">{body.legalName}</dd>
                    </div>
                    <div className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 border-t py-6">
                        <dt className="font-medium">Description</dt>
                        <dd className="sm:col-span-2 text-muted-foreground">{body.description}</dd>
                    </div>
                    <div className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 border-t py-6">
                        <dt className="font-medium">Founded</dt>
                        <dd className="sm:col-span-2 text-muted-foreground">{body.foundedYear}</dd>
                    </div>
                    <div className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 border-t py-6">
                        <dt className="font-medium">Category</dt>
                        <dd className="sm:col-span-2 text-muted-foreground">
                            {body.category?.sector} / {body.category?.industry} / {body.category?.subIndustry}
                        </dd>
                    </div>
                    <div className="grid gap-1 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-3 border-t py-6">
                        <dt className="font-medium">Tags</dt>
                        <dd className="sm:col-span-2 flex flex-wrap gap-2">
                            {body.tags?.map((tag: string) => (
                                <Badge key={tag} variant="secondary">{tag}</Badge>
                            ))}
                        </dd>
                    </div>
                </CardContent>
            </Card>

            {/* Metrics Card */}
            <Card>
                <CardHeader>
                    <CardTitle>Company Metrics</CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                        {body.metrics?.employees && (
                            <div className="p-4 border rounded-lg">
                                <div className="text-sm text-muted-foreground">Employees</div>
                                <div className="text-2xl font-bold">{body.metrics.employees.toLocaleString()}</div>
                                <div className="text-xs text-muted-foreground">{body.metrics.employeesRange}</div>
                            </div>
                        )}
                        {body.metrics?.annualRevenue && (
                            <div className="p-4 border rounded-lg">
                                <div className="text-sm text-muted-foreground">Annual Revenue</div>
                                <div className="text-2xl font-bold">${(body.metrics.annualRevenue / 1000000).toFixed(1)}M</div>
                                <div className="text-xs text-muted-foreground">{body.metrics.estimatedAnnualRevenue}</div>
                            </div>
                        )}
                        {body.metrics?.marketCap && (
                            <div className="p-4 border rounded-lg">
                                <div className="text-sm text-muted-foreground">Market Cap</div>
                                <div className="text-2xl font-bold">${(body.metrics.marketCap / 1000000000).toFixed(1)}B</div>
                            </div>
                        )}
                    </div>
                </CardContent>
            </Card>

            {/* Location Card */}
            <Card>
                <CardHeader>
                    <CardTitle>Location & Contact</CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="grid gap-4 md:grid-cols-2">
                        <div>
                            <div className="font-medium mb-2">Address</div>
                            <div className="text-muted-foreground">
                                {body.geo?.streetNumber} {body.geo?.streetName}<br />
                                {body.geo?.city}, {body.geo?.stateCode} {body.geo?.postalCode}<br />
                                {body.geo?.country}
                            </div>
                        </div>
                        <div>
                            <div className="font-medium mb-2">Contact</div>
                            <div className="text-muted-foreground">
                                {body.phone && <div>Phone: {body.phone}</div>}
                                {body.url && <div>Website: <a href={body.url} target="_blank" rel="noopener noreferrer" className="text-primary hover:underline">{body.url}</a></div>}
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>

            {/* Social Media Card */}
            {(body.twitter || body.linkedin || body.facebook) && (
                <Card>
                    <CardHeader>
                        <CardTitle>Social Media</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="grid gap-4 md:grid-cols-3">
                            {body.twitter && (
                                <div className="p-4 border rounded-lg">
                                    <div className="font-medium mb-2 flex items-center gap-2">
                                        <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor"><path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/></svg>
                                        Twitter
                                    </div>
                                    <div className="text-sm text-muted-foreground">
                                        @{body.twitter.handle}<br />
                                        {/* {body.twitter.followers.toLocaleString()} followers */}
                                    </div>
                                </div>
                            )}
                            {body.linkedin && (
                                <div className="p-4 border rounded-lg">
                                    <div className="font-medium mb-2 flex items-center gap-2">
                                        <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor"><path d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z"></path></svg>
                                        LinkedIn
                                    </div>
                                    <div className="text-sm text-muted-foreground">
                                        {body.linkedin.handle && <div>{body.linkedin.handle}</div>}
                                        {body.linkedin.industry}
                                    </div>
                                </div>
                            )}
                            {body.facebook && (
                                <div className="p-4 border rounded-lg">
                                    <div className="font-medium mb-2 flex items-center gap-2">
                                        <svg className="w-5 h-5" viewBox="0 0 24 24" fill="currentColor"><path d="M9.101 23.691v-7.98H6.627v-3.667h2.474v-1.58c0-4.085 1.848-5.978 5.858-5.978.401 0 .955.042 1.468.103a8.68 8.68 0 0 1 1.141.195v3.325a8.623 8.623 0 0 0-.653-.036 26.805 26.805 0 0 0-.733-.009c-.707 0-1.259.096-1.675.309a1.686 1.686 0 0 0-.679.622c-.258.42-.374.995-.374 1.752v1.297h3.919l-.386 2.103-.287 1.564h-3.246v8.245C19.396 23.238 24 18.179 24 12.044c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.628 3.874 10.35 9.101 11.647Z"/></svg>
                                        Facebook
                                    </div>
                                    <div className="text-sm text-muted-foreground">
                                        {body.facebook.handle}
                                    </div>
                                </div>
                            )}
                        </div>
                    </CardContent>
                </Card>
            )}

            {/* Technologies Card */}
            <Card>
                <CardHeader>
                    <CardTitle>Technologies ({body.tech?.length ?? 0})</CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="flex flex-wrap gap-2">
                        {body.tech?.map((tech: string) => (
                            <Badge key={tech} variant="outline" className="capitalize">
                                {tech.replace(/_/g, ' ')}
                            </Badge>
                        ))}
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}

const renderApiFields = (fields, selected) => {
    switch (selected) {
        case 'security':
            return (
                <Field
                    labelProps={{ htmlFor: fields.domain.id, children: 'Domain' }}
                    inputProps={{
                        ...getInputProps(fields.domain, { type: 'text' }),
                        placeholder: 'stripe.com'
                    }}
                    errors={fields.domain.errors}
                />
            );
        case 'enrichment':
            return (
                <Field
                    labelProps={{ htmlFor: fields.domain.id, children: 'Domain' }}
                    inputProps={{
                        ...getInputProps(fields.domain, { type: 'text' }),
                        placeholder: 'example.com'
                    }}
                    errors={fields.domain.errors}
                />
            );
        case 'entity_resolution':
            return (
                <>
                    <Field
                        labelProps={{ htmlFor: fields.name.id, children: 'Company Name' }}
                        inputProps={{
                            ...getInputProps(fields.name, { type: 'text' }),
                            placeholder: 'Netflix, Inc.'
                        }}
                        errors={fields.name.errors}
                    />
                    <Field
                        labelProps={{ htmlFor: fields.location.id, children: 'Location (optional)' }}
                        inputProps={{
                            ...getInputProps(fields.location, { type: 'text' }),
                            placeholder: 'New York, NY'
                        }}
                        errors={fields.location.errors}
                    />
                </>
            );
        default:
            return null;
    }
};

function getForm(selected: string) {

    if (selected === 'entity_resolution') {
        return useForm({
            id: 'api-request-form',
            constraint: getZodConstraint(EntityResolutionSchema),
            onValidate({ formData }) {
                return parseWithZod(formData, { schema: EntityResolutionSchema })
            },
            defaultValue: {
                api: 'entity_resolution',
                name: 'Netflix, Inc.',
            },
        });
    }
    else if (selected === 'enrichment') {
        return useForm({
            id: 'api-request-form',
            constraint: getZodConstraint(EnrichmentSchema),
            onValidate({ formData }) {
                return parseWithZod(formData, { schema: EnrichmentSchema })
            },
            defaultValue: {
                api: 'enrichment',
                domain: 'example.com',
            },
        });
    }
    else {
        return useForm({
            id: 'api-request-form',
            constraint: getZodConstraint(Schema),
            onValidate({ formData }) {
                return parseWithZod(formData, { schema: Schema })
            },
            defaultValue: {
                api: 'security',
                domain: 'stripe.com',
            },
        });
    }
}

function getDefaultApiOption(accountOption) {
    if (accountOption?.securityApiEnabled) {
        return 'security';
    }
    else if (accountOption?.entityResolutionApiEnabled) {
        return 'entity_resolution';
    }
    else if (accountOption?.enrichmentApiEnabled) {
        return 'enrichment';
    }
    return '';
}


export function Playground() {
    const data = useLoaderData<typeof loader>();
    let accountOption = data?.user?.account?.account?.accountOption;
    const fetcher = useFetcher();

    const [selected, setSelected] = useState<string>(getDefaultApiOption(accountOption));
    const [form, fields] = getForm(selected);

    useEffect(() => {
        if (fetcher.state === 'idle' && fetcher.data?.error) {
            const err = fetcher.data.error;
            toast.error(err.title, {
                description: err.description,
            });
        }
    }, [fetcher.state, fetcher.data]);

    return (
        <div className="flex flex-col">
            <header className="sticky top-0 z-10 flex h-[57px] items-center gap-1 border-b bg-background px-4 md:hidden">
            </header>
            <main className="grid flex-1 gap-4 overflow-auto p-4 md:grid-cols-2 lg:grid-cols-3">
                <div className="relative hidden flex-col items-start gap-8 md:flex">
                    <fetcher.Form method="POST" {...getFormProps(form)} className="grid w-full items-start gap-6">
                        <fieldset className="grid gap-6 rounded-lg border p-4">
                            <legend className="-ml-1 px-1 text-sm font-medium">
                                Settings
                            </legend>
                            <div className="grid gap-3">
                                <SelectField
                                    labelProps={{ htmlFor: fields.api.id, children: 'Select an API' }}
                                    selectProps={{
                                        ...getInputProps(fields.api, { type: 'text' }),
                                        onValueChange: (val) => {
                                            setSelected(val);
                                        }
                                    }}
                                    triggerProps={{
                                        className: "items-start [&_[data-description]]:hidden"
                                    }}
                                    placeholder='Select API'
                                    errors={fields.api.errors}
                                >
                                    {accountOption?.securityApiEnabled && (
                                        <SelectItem value="security">
                                            <div className="flex items-start gap-3 text-muted-foreground">
                                                <Building2 className="size-5" />
                                                <div className="grid gap-0.5">
                                                    <p>AI Security Analyst</p>
                                                    <p className="text-xs" data-description>
                                                        Run a realtime search on a software vendor by domain.
                                                    </p>
                                                </div>
                                            </div>
                                        </SelectItem>
                                    )}
                                    {accountOption?.entityResolutionApiEnabled && (
                                        <SelectItem value="entity_resolution">
                                            <div className="flex items-start gap-3 text-muted-foreground">
                                                <Building2 className="size-5" />
                                                <div className="grid gap-0.5">
                                                    <p>Entity Resolution</p>
                                                    <p className="text-xs" data-description>
                                                        Identify a company by name and optional location / address.
                                                    </p>
                                                </div>
                                            </div>
                                        </SelectItem>
                                    )}
                                    {accountOption?.enrichmentApiEnabled && (
                                        <SelectItem value="enrichment">
                                            <div className="flex items-start gap-3 text-muted-foreground">
                                                <Building2 className="size-5" />
                                                <div className="grid gap-0.5">
                                                    <p>Enrichment</p>
                                                    <p className="text-xs" data-description>
                                                        Enrich website data by domain name.
                                                    </p>
                                                </div>
                                            </div>
                                        </SelectItem>
                                    )}

                                </SelectField>
                            </div>
                            {renderApiFields(fields, selected)}
                        </fieldset>

                        <div className="">
                            <ErrorList errors={form.errors} id={form.errorId} />
                            <div className="ml-auto flex items-center gap-2">
                                <StatusButton
                                    type="submit"
                                    name="intent"
                                    value={requestIntent}
                                    className="ml-auto gap-1.5"
                                    status={fetcher.state !== 'idle' ? 'pending' : form.status ?? 'idle'}
                                >
                                    <span className="sr-only sm:not-sr-only sm:whitespace-nowrap">
                                        Send
                                    </span>
                                </StatusButton>
                            </div>
                        </div>
                    </fetcher.Form>
                </div>
                {_renderResponse(fetcher)}
            </main>
        </div>
    )
}

function _renderSecurityResponse(res) {
    return (
        <Tabs defaultValue="pretty">
            <div className="flex items-center">
                <TabsList>
                    <TabsTrigger value="pretty">Pretty</TabsTrigger>
                    <TabsTrigger value="code">Code</TabsTrigger>
                </TabsList>
            </div>
            <TabsContent value="pretty">
                {responsePretty(res.data)}
            </TabsContent>
            <TabsContent value="code">
                {responseCodeHighlight(res.data)}
            </TabsContent>
        </Tabs>)
}



function _renderEntityResolutionResponse(res) {
    return (
        <Tabs defaultValue="pretty">
            <div className="flex items-center">
                <TabsList>
                    <TabsTrigger value="pretty">Pretty</TabsTrigger>
                    <TabsTrigger value="code">Code</TabsTrigger>
                </TabsList>
            </div>
            <TabsContent value="pretty">
                {responseEntityResolutionPretty(res.data)}
            </TabsContent>
            <TabsContent value="code">
                {responseCodeHighlight(res.data)}
            </TabsContent>
        </Tabs>)
}

function _renderEnrichmentResponse(res) {
    return (
        <Tabs defaultValue="pretty">
            <div className="flex items-center">
                <TabsList>
                    <TabsTrigger value="pretty">Pretty</TabsTrigger>
                    <TabsTrigger value="code">Code</TabsTrigger>
                </TabsList>
            </div>
            <TabsContent value="pretty">
                {responseEnrichmentPretty(res.data)}
            </TabsContent>
            <TabsContent value="code">
                {responseCodeHighlight(res.data)}
            </TabsContent>
        </Tabs>
    )
}



function _renderResponse(fetcher) {
    let res = fetcher.data?.res;
    let content;
    if (res?.api === 'security') {
        content = _renderSecurityResponse(res);
    }
    else if (res?.api === 'entity_resolution') {
        content = _renderEntityResolutionResponse(res);
    }
    else if (res?.api === 'enrichment') {
        content = _renderEnrichmentResponse(res);
    }
    return (
        <div className="relative flex h-full min-h-[50vh] flex-col rounded-xl bg-muted/50 p-4 lg:col-span-2">
            <Badge variant="outline" className="absolute right-3 top-3">
                Output
            </Badge>
            <div className="flex-1">
                {content}
            </div>
        </div>
    )
}